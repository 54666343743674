
import './App.css';
import './assets/css/About.css'
import './assets/css/Home.css'
import './assets/css/Header.css'
import './assets/css/Footer.css'
import './assets/css/Business.css'
import './assets/css/Private.css'
import './assets/css/Dlf.css'
import './assets/css/Angebot.css'
import './assets/css/New.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScrollTop from "./ScrollTop.js"
import React, { lazy, Suspense } from 'react'
import MoonLoader from "react-spinners/ClipLoader";

const Home = lazy(() => import('./components/Home'))
const Header = lazy(() => import('./Header'))
const Footer = lazy(() => import('./components/homeComponents/Footer'))
const About = lazy(() => import('./components/About'))
const Business = lazy(() => import('./components/Business'))
const PrivateKunden = lazy(() => import('./components/PrivateKunden'))
const DLF = lazy(() => import('./components/Dlf'))
const Angebot = lazy(() => import('./components/Angebot'))
const PrivateFinanzen = lazy(() => import('./components/PrivateFinanzen'))
const Datenschutz = lazy(() => import('./components/Datenschutz'))
const New = lazy(() => import('./components/New'))

function App() {
  

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<div className="loaderStyle"><MoonLoader size={100} color="#50b8e7" /></div>}>
          <ScrollTop>
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/uber-uns" element={<About />} />
              <Route exact path='/geschaftskunden' element={<Business />} />
              <Route exact path='/privatkunden/versicherungen' element={<PrivateKunden />} />
              <Route exact path='/privatkunden/finanzen' element={<PrivateFinanzen />} />
              <Route exact path='/DLF' element={<DLF />} />
              <Route exact path='/angebot' element={<Angebot />} />
              <Route exact path='/Datenschutz-Impressum' element={<Datenschutz />} />
              <Route exact path='/kunden-werben' element={<New />} />
            </Routes>
            <div className='pt-5 mt-5'>
              <Footer />
            </div>
          </ScrollTop>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;

