import { useEffect } from "react";
import { useLocation } from "react-router";
const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, [location]);

    useEffect(() => {
        var splitLocation = location.pathname.split('/')
        var title = splitLocation[splitLocation.length - 1]

        if (title.includes('-')) {
            var temp = title.split('-')

            document.title = temp.map((t) => {
                return t[0].toUpperCase() + t.substring(1);
            }).join(" ");
        }
        else if ((title === "")) {
            document.title = 'DL Finance'
        }
        else {
            document.title = title[0].toUpperCase() + title.substring(1)
        }

    }, [location])


    return <>{props.children}</>
};
export default ScrollToTop;